<template>
    <layout-login class="body">
        <v-container>
            <v-card width="400" class="mx-auto mt-10 pa-7" rounded="lg">
                <v-card-title class="pb-3 text-h4">
                    Login
                </v-card-title>
                <v-card-text>
                    <v-form>
                    <span>Username</span>
                    <v-text-field 
                        prepend-inner-icon="mdi-account-circle"
                        v-model="user.username"
                        outlined dense
                    />
                    <span>Password</span>
                    <v-text-field 
                        :type="showPassword ? 'text' : 'password'" 
                        prepend-inner-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        v-model="user.password"
                        outlined dense
                    />
                    </v-form>
                    <div class="red--text text-center">{{errorMsg}}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="login()">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </layout-login>
</template>
<script>
export default {
    data:() => ({
        showPassword: false,
        user: {},
        errorMsg: '',
    }),
    methods: {
        login() {
            // if (this.$route.query.redirect)
            // console.log(this.$route.query.redirect)
            // else console.log('undd')
            this.$store.dispatch('login', this.user).then(response=> {
                if (response.data.status!='success') return this.errorMsg = 'Login failed!'
                if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
                else this.$router.push('/')
                console.log('refreshing')
                window.location.reload()
            }, err=> {
                console.log(err)
                return this.errorMsg = 'Login failed!'
            })
        }
    }
}
</script>
<style>
.body {
    background-image: url('../assets/login-bg.jpg');
}
</style>